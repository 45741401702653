<template>
  <div>
    <form @submit.prevent="onsubmit">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12" v-if="ano != 0">
          <label>Selecione o Ano:</label>
          <select class="p-inputtext p-component"
            style="appearance: revert !important;width: 100% !important;"
            v-model="anoObject" >
            <option v-for="a in anoALL" :key="a.ano" :value="a" >{{a.ano}}</option> 
          </select> 
        </div>
        <div class="field col-12 md:col-12">
          <pm-InputText v-model="login" placeholder="Login"  />
        </div>
        <div class="field col-12 md:col-12">
          <pm-Password v-model="password" placeholder="Senha"
            v-on:keyup.enter="onsubmit" :feedback="false" />
        </div>
        <div class="field col-12 md:col-4">
          <pm-Button label="Acessar"  @click="onsubmit" style="background: #014aad;border: 1px solid #014aad;" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>


import { LoginInfo } from "@/class/login.js";
import { Calendario } from "@/class/calendario";
import { Configuracao } from "@/class/configuracao";

import axios from 'axios'

export default {
  name: 'login',
  data () {
    return {
      login: '',
      password: '',
      ano:0,
      anoObject: {},
      situacao:0,
      keepLoggedIn: false,
      loginErrors: [],
      passwordErrors: [],
      isLoading: false,
      data: null,
      anoAll:[]
    }
  },
  computed: {
    formReady () {
      return !this.loginErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    async onsubmit () {
      try {
        this.loginErrors = this.login ? [] : ['O login é obrigatório']
        this.passwordErrors = this.password ? [] : ['A senha é obrigatória']
        if (!this.formReady) {
          return
        }
        this.isLoading = true;

        const data = await LoginInfo.Logar(this.login, this.password);
        this.isLoading = false;
        //console.log(data);

        // começa aqui
        const token = sessionStorage.getItem("token");
        this.ano = this.anoObject.ano;
        this.situacao = this.anoObject.situacao;

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + '';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem("token");
        const user = await LoginInfo.WhoIam(token, this.ano);
        sessionStorage.setItem("funcaoDoUsuario", user.data.funcao);
        //deletar esse if quando o 2fa for para produção
        if (data) {
                   // const user = await LoginInfo.WhoIam(token);

          // this.$router.push({ name: '2fa',params: { email:this.login, ano:this.ano, situacao:this.situacao, nome: user.data.usuario.nome }});

          /**
           * Function redirecionamento 
           * OBS .: params 1 vai para o dashbord. params 0 vai para o A2F 
           * 
           * 
           * Function dateAuthorizationCacher
           * OBS .: Verifica se data que está no LocalStorage é igual ou maior que a data atual.
           * objetivo é averiguar se a sessão do usuario não estar expirada. a sessão dura 7 dias
          */
          
          if(this.dateAuthorizationCacher()){
            //data está autorizada ele redireciona diretamente para o dashboard 
            this.redirecionamento(1,token)
          }else{
            if(this.dateAuthorizationCacher() == false){
              //data não está autorizada ele redireciona diretamente para o A2F
              //so caie nesse if se já existir um localStorage para esse usuario
              this.redirecionamento(0,token)
            }
            // quando não tiver data no localStorage ele cai nesse if
            if(typeof this.dateAuthorizationCacher() === 'undefined' || this.dateAuthorizationCacher() === null){

              if(window.location.hostname === 'localhost'){
                //redireciona diretamente para o dashboard 
                this.redirecionamento(1,token)
              }else{
                //redireciona diretamente para o para o A2F 
                //this.redirecionamento(0,token)

                //redireciona diretamente para o dashboard 
                this.redirecionamento(1,token)
              }
            }
          }
        }
        else {
            this.$vaToast.init({
            message: "Login ou senha incorreta! Tente novamente!",
            iconClass: 'fa-star-o',
            position: 'botoom-left',
            color:'danger',
            duration: 2500,
            fullWidth: false,
          })
        }
      }
      catch(e) {
        console.log(e);
      }
    },

    dateAuthorizationCacher(){
      const authPerson = localStorage.getItem("auth_"+this.login);

      if(authPerson){
        const authPersonJson = JSON.parse(authPerson)
 
        const minhaData = new Date(`${authPersonJson.ano}-${authPersonJson.mes}-${authPersonJson.dia} 23:59:59`);
        const dataAgora = new Date();

        if (minhaData.getTime() < dataAgora.getTime()) {
          //console.log("A data é  menor que a data atual");
          return false
        } else {
          //console.log("A data é maior ou igual que a data atual");
          return true;
        }
      }else{
        return null;
      }
    },

    async redirecionamento(n,token){
      const user = await LoginInfo.WhoIam(token, this.ano);
      let anoEnvio;
      let anoSituacao;
      
      this.ano = this.anoObject.ano;
      anoEnvio =  this.anoObject.ano;
      anoSituacao = this.anoObject.situacao;

      if(n){
      
        /*if (this.ano.ano == undefined) {
          anoEnvio = this.ano;
          anoSituacao = this.situacao;
        }
        else {
          anoEnvio = this.ano.ano;
          anoSituacao = this.ano.situacao
        }*/
        sessionStorage.setItem("anoSelecionado", anoEnvio);
        sessionStorage.setItem("anoSitucao", 0);

        const dt = await axios.get('/config/sistema');
        if(dt){
          sessionStorage.setItem("colorBar", dt.data.barralateral_cor);
          sessionStorage.setItem("colorBar_text", dt.data.barralateral_cortext);
          sessionStorage.setItem("colorDiaLetivo", dt.data.dia_letivo_cor);
          sessionStorage.setItem("presencaMarcada", dt.data.presenca_marcar.todas_marcadas);
        }

        if(user.data.funcao == 1 || user.data.funcao == 2 || user.data.funcao == 7){
          const data1 = await Calendario.verificarExisteCalendario();
          if (data1.data['error'] == "Não encontrado!") {
            this.$router.push({ name: 'calendario' });
          }
          else {
            const dt = await Configuracao.obtemOne(data1.data.id);
            if(dt.data.id){
              if(dt.data.autorizado == 1){

                // this.$router.push({ name: '2fa',params: { email:this.login }});
                this.$router.push({ name: 'painel' });
              }else{
                this.$router.push({ name: 'calendario' });
              }
            }else{
                this.$router.push({ name: 'calendario' });
            }
          }
        }
        else if(user.data.funcao == 5 ){
          if(user.data.diretor == 0){
            this.$router.push({ name: 'painel-professor' });
          }else{
            sessionStorage.setItem("coordenacao",1);
            this.$router.push({ name: 'coordenacao-escola' });
          }
        }
        else if(user.data.funcao == 4){
          sessionStorage.setItem("coordenacao",2);
          this.$router.push({ name: 'coordenacao-escola' });
        }
        else if(user.data.funcao == 3){
          sessionStorage.setItem("coordenacao",1);
          this.$router.push({ name: 'coordenacao-escola' });
        }
        /*if(user.permissao.funcao_id == "6" ){
          this.$router.push({ name: 'escolas' });
        }*/
        //termina aqui
        return false
      }else{
        this.$router.push({ name: '2fa',params: { email:this.login, ano:this.ano, situacao:this.situacao, nome: user.data.usuario.nome }});
        return false
      }
    },

    async anosCalendario () {
      try {
        const dt = await Calendario.ano();
        this.anoALL = dt.data;
        this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
        for (const l of dt.data) {
          //if(l.situacao == 1){
            this.ano = l.ano;
            this.anoObject = l;
            this.situacao = l.situacao;
          //}
        }
      } catch (e) {

      }
    }
  },
  async beforeMount(){
    sessionStorage.removeItem("token");
    sessionStorage.removeItem('anoSelecionado');
    sessionStorage.removeItem('anoSituacao');
    await this.anosCalendario();
    /*var today = new Date();
    var dataAtual = today.getFullYear();
    this.ano = dataAtual;*/
  }
}
</script>
